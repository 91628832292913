$(document).on('click', '.alert-dismissable .close, .alert-dismissible .close', function () {
    $(this).closest('.alert-dismissible').remove();
});

$(document).ready(function () {
    const upgradePlanAlert = $('#upgrade-plan-alert');
    const pageHeading = $('.page-heading');
    if (upgradePlanAlert && pageHeading.length) {
        upgradePlanAlert.insertAfter(pageHeading);
    }
});
